<template>
  <MainMenu />
</template>

<style lang="scss">
$mainColor:#002cee;
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: $mainColor;
}
</style>
<script>


// @ is an alias to /src
import MainMenu from '@/components/MainMenu.vue'

export default {
  name: 'App',
  components: {
    MainMenu
  }
}
</script>