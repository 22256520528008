<template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> | 
    <router-link to="/odds">Odds</router-link>
  </nav>
  <router-view/>
</template>

<script>
export default {
  name: 'MainMenu',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
